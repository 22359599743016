import React from 'react';
import './plansSection.css';
import PlanCard from '../../components/PlanCard/PlanCard';
import SectionTitle from '../../components/SectionTitle/SectionTitle';

const plans = [
  {
    title: 'BÁSICO',
    features: ['Trading completo de 0 a 100', 'Estrategia exclusiva', 'PDFs exclusivos'],
    notIncluded: ['Gestión de riesgo', 'Psicotrading', 'Plataforma backtesting', 'Acceso a comunidad privada', 'Apoyo las 24 horas', 'Mentoria 1 a 1'],
    button: 'SOLD OUT',
    planClass: 'plan-basic',
    price: '105€',
    url: "https://pay.hotmart.com/T91252374S"
  },
  {
    title: 'AVANZADO',
    features: ['Trading completo de 0 a 100', 'Estrategia exclusiva', 'PDFs exclusivos', 'Gestión de riesgo', 'Psicotrading', 'Plataforma backtesting'],
    notIncluded: ['Acceso a comunidad privada', 'Apoyo las 24 horas', 'Mentoria 1 a 1'],
    button: 'SOLD OUT',
    planClass: 'plan-advanced',
    price: '220€',
    url: "https://pay.hotmart.com/M91252410N"
  },
  {
    title: 'PREMIUM',
    features: ['Trading completo de 0 a 100', 'Estrategia exclusiva', 'PDFs exclusivos', 'Gestión de riesgo', 'Psicotrading', 'Plataforma backtesting', 'Acceso a comunidad privada', 'Apoyo las 24 horas', 'Mentoria 1 a 1'],
    notIncluded: [''],
    button: 'SOLD OUT',
    planClass: 'plan-premium',
    price: '325€',
    url: "https://pay.hotmart.com/D88880064L"
  },
];

const isMobile = window.innerWidth <= 768;

function PlansSection() {
  return (
    <div className="plans-section">
       <SectionTitle
        text="Planes diseñados para"
        strongText="traders"
        className={isMobile ? "title-container-large" : "title-container"}
      />
      <div className="plans-container">
        {plans.map((plan, index) => (
          <PlanCard key={index} plan={plan} />
        ))}
      </div>
    </div>
  );
}

export default PlansSection;
