import React from 'react';
import './servicesSection.css'; // Asegúrate de que la ruta coincida con la ubicación real del archivo CSS
import estrategia from '../../assets/images/estrategia.png';
import comunidad from '../../assets/images/comunidad.png';
import online from '../../assets/images/online.png'
import fondeado1 from '../../assets/images/fondeado1.png'
import fondeado2 from '../../assets/images/fondeado2.png'
import SectionTitle from '../../components/SectionTitle/SectionTitle';

// Datos de los servicios
const services = [
  {
    id: "service-1",
    image: online,
    title: "MENTORÍA PERSONALIZADA",
    description: "Mentoría 1:1 personalizada para resolver todas tus dudas",
  },
  {
    id: "service-2",
    image: estrategia,
    title: "ESTRATEGIA VALIDADA",
    description: "Obtendrás 2 estrategias testeadas y validadas.",
  },

  {
    id: "service-3",
    image: comunidad,
    title: "COMUNIDAD ACTIVA",
    description: "Dispondrás de un grupo privado donde analizamos y operamos el mercado juntos.",
  },

];

const isMobile = window.innerWidth <= 768;
function ServiceSection() {
  return (
    <section className="services-section">
      <SectionTitle
        text="¿Quiénes somos?"
        strongText=""
        className={isMobile ? "title-container-large" : "title-container"}
      />

      <div className='text-container'>
        <span>Somos un equipo de traders con más de <strong style={{ color: '#2d91d4' }}>4 años de experiencia</strong> en los mercados financieros.</span>
        <span>¡Descubre cómo maximizar tus ganancias con nuestras <strong style={{ color: '#2d91d4' }}>estrategias validadas</strong> y asesoramiento experto para que puedas lograr la ansiada <strong style={{ color: '#2d91d4' }}>rentabilidad</strong>!</span>
      </div>
      <div className="services-grid">
        {services.map(service => (
          <div key={service.id} className="service">
            <img src={service.image} alt={service.title} />
            <h3>{service.title}</h3>
            <p className="italic">{service.description}</p>
          </div>
        ))}
      </div>
      <SectionTitle
        text="Alumnos Fondeados"
        strongText=""
        className={isMobile ? "title-container-large" : "title-container"}
      />

      <div className='text-container'>

        <div style={{ display: "flex", flexDirection: "column", gap: "30px", marginTop: "2rem", justifyContent: "center" }}>
          <div style={{ gap: "20px", padding: "0 0", paddingBottom: "1.5rem" }}>
            <span> Ejemplos de alumnos que han logrado<strong style={{ color: '#2d91d4' }}> fondear</strong>  sus cuentas gracias a nuestras <strong style={{ color: '#2d91d4' }}>estrategias</strong> y <strong style={{ color: '#2d91d4' }}>mentoría</strong>.</span>
            <span> ¡Descubre cómo <strong style={{ color: '#2d91d4' }}>tú </strong>también puedes ser parte de nuestros <strong style={{ color: '#2d91d4' }}>casos de éxito!</strong></span>
          </div>
        </div>

      </div>
      <div style={{ display: "flex", gap: "100px", justifyContent: "center" }}>
        <img style={{ width: "35%" }} src={fondeado1} ></img>
        <img style={{ width: "35%" }} src={fondeado2} ></img>
      </div>


    </section>
  );
}

export default ServiceSection;
