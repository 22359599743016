import './whatsappbutton.css';
import React from 'react';
import whatsappLogo from './whatsapp-logo.svg';

function WhatsAppButton() {
  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;

  return (
    <a href={`https://wa.me/${whatsappNumber}?text=Hola,%20he%20visto%20los%20planes%20de%20trading%20en%20su%20web%20y%20me%20gustaría%20recibir%20más%20información%20sobre%20cómo%20pueden%20ayudarme%20a%20mejorar%20mis%20resultados.`}
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-floating-button">
      <img src={whatsappLogo} alt="WhatsApp" />
    </a>
  );
}

export default WhatsAppButton;
