import React, { useEffect, useState } from 'react';
import './navbar.css';
import logo from '../../assets/images/logo.png';

function Navbar() {
  const [navBackground, setNavBackground] = useState('transparent');
  const [activeSection, setActiveSection] = useState('');

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isMobile = window.innerWidth <= 768;

  const handleScroll = () => {
    const sections = ['inicio', 'quienes-somos', 'servicios', 'planes'];
    const currentSection = sections.find(section => {
      const element = document.getElementById(section);
      if (element) {
        const rect = element.getBoundingClientRect();
        return rect.top >= 0 && rect.top < window.innerHeight;
      }
      return false;
    });

    if (currentSection) {
      setActiveSection(currentSection);
    }

    const show = window.scrollY > 0;
    if (show) {
      setNavBackground('#000'); // Color de fondo deseado al hacer scroll
    } else {
      setNavBackground('transparent');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleButtonClick = (sectionId: any) => {
    scrollToSection(sectionId);

    // Reinicia el estado de 'focus' del botón para prevenir que se quede "pegado"
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    toggleMenu()
  };

  return (
    <nav className="Navbar" style={{ backgroundColor: navBackground }}>
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      {isMobile &&
        <button onClick={toggleMenu} className="hamburger-menu">
          {isMenuOpen ? '✕' : '☰'}
        </button>
      }
      <div className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
        <a href="#servicios" onClick={() => handleButtonClick('servicios')} className={`nav-button ${activeSection === 'servicios' ? 'active' : ''}`}>¿QUIÉNES SOMOS?</a>
        <a href="#planes" onClick={() => handleButtonClick('planes')} className={`nav-button ${activeSection === 'planes' ? 'active' : ''}`}>FORMACIÓN</a>
        <a href="#testimonios" onClick={() => handleButtonClick('testimonios')} className={`nav-button ${activeSection === 'testimonios' ? 'active' : ''}`}>CASOS DE ÉXITO</a>
      </div>
    </nav>
  );
}

export default Navbar;