import { useEffect, useState, useRef } from 'react';
import './introSection.css';
import play from '../../assets/images/play_8488948.png'
import intro from '../../assets/images/intro.jpeg';
import MainButton from '../../components/MainButton';
import CountdownTimer from '../../components/CountdownTimer/CountdownTimer';

function IntroSection() {
  const isMobile = window.innerWidth <= 480;
  const [showVideo, setShowVideo] = useState(false);
  const videoContainerRef = useRef(null);

  const handleScroll = () => {
    const element = document.getElementById('suscription');
    if (element) {
      const rect = element.getBoundingClientRect();
      if (rect.top >= 0 && rect.top < window.innerHeight) {
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleButtonClick = (sectionId: any) => {
    scrollToSection(sectionId);

    // Reinicia el estado de 'focus' del botón para prevenir que se quede "pegado"
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const handlePlayVideo = () => {
    setShowVideo(true);
    if (videoContainerRef.current) {
      const container = videoContainerRef.current as any;
      if (container.requestFullscreen) {
        container.requestFullscreen().catch((err: { message: any; name: any; }) => {
          console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
      }
    }
  };

  return (
    <section className="intro-section">
      <div className='intro-container'>
        <div className='intro-title-container'>
          <h1>FONDÉATE CON ÉXITO</h1>
          
        </div>
      </div>
      {!showVideo && (
        <div className="video-thumbnail" onClick={handlePlayVideo}>
          <img src={intro} alt="Play Video" />
          <button className="play-button">
            <img src={play} alt="Play Video" />
          </button>
        </div>
      )}
      {showVideo && (
        <iframe
          id="fullScreenVideo"
          className="video"
          width={isMobile ? "375" : "800"}
          height={isMobile ? "210" : "450"}
          src="https://www.youtube.com/embed/hWZ_BU_gkcM?autoplay=1"
          title="FONDÉATE CON ÉXITO"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      )}
      <div className='subtitle-container'>
        <h2 className="subtitulo-destacado">Aprende a tradear y fondear tus cuentas</h2>
        <h2 className='subtitulo'>Plazas <strong style={{ color: '#2d91d4' }}>CERRADAS</strong> temporalmente</h2>
      </div>
      <CountdownTimer/>
      <MainButton onClick={() => handleButtonClick('suscription')}>¡PREINSCRIBIRME!</MainButton>
    </section>
  );
}

export default IntroSection;
