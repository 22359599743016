import React from 'react';
import MainButton from '../../components/MainButton';
import './PlanCard.css';
interface Plan {
  title: string;
  features: string[];
  notIncluded: string[];
  button: string;
  planClass: string;
  price: string;
  url: string;
}

interface PlanCardProps {
  plan: Plan;
}

const PlanCard: React.FC<PlanCardProps> = ({ plan }) => {
  return (
    <div className={`plan ${plan.planClass}`}>
      <div>
        <div className="plan-header">
          <span>{plan.title.toUpperCase()}</span>
          <span>{plan.price.toUpperCase()}</span>
        </div>
        <ul>
          {plan.features.map((feature, featureIndex) => (
            <li key={featureIndex}><span className="check-icon">✓</span>{feature}</li>
          ))}
          {plan.notIncluded.filter(notIncludedItem => notIncludedItem !== "").map((notIncluded, notIncludedIndex) => (
            <li key={notIncludedIndex}><span className="cross-icon">✕</span>{notIncluded}</li>
          ))}
        </ul>
      </div>
      <MainButton isDisabled={true} onClick={() => window.location.href = plan.url}>
        {plan.button}
      </MainButton>
    </div>
  );
};

export default PlanCard;
