import React, { useState, useEffect } from 'react';

const CountdownTimer = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const targetDate = new Date('November 29, 2024 00:00:00').getTime();

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date().getTime(); // Obtener la hora local del navegador
      const distance = targetDate - now;

      if (distance < 0) {
        clearInterval(intervalId);
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

      setDays(days);
      setHours(hours);
      setMinutes(minutes);
    };

    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  return (
    <div style={{ textAlign: 'center', color: 'white' }}>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', color: '#2d91d4' }}>
        <div>
          <span style={{ fontSize: '80px' }}>{days.toString().padStart(2, '0')}</span>
          <div style={{ fontSize: '30px' }}>Días</div>
        </div>
        <div>
          <span style={{ fontSize: '80px' }}>{hours.toString().padStart(2, '0')}</span>
          <div style={{ fontSize: '30px' }}>Horas</div>
        </div>
        <div>
          <span style={{ fontSize: '80px' }}>{minutes.toString().padStart(2, '0')}</span>
          <div style={{ fontSize: '30px' }}>Minutos</div>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
